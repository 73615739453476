import React from 'react'
import { injectIntl } from 'react-intl'
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

const Newsletter = (data) => {
  const { sfondo } = useStaticQuery(
    graphql`
      query {
        sfondo: file(name: {eq: "uccellini_newsletter"}) {
          name
          childImageSharp {
            fluid(maxWidth:1110) {
                ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  const { intl: { formatMessage } } = data;
  return (
    <div className="section">
      <div className="row">
        <div className="col-12">
         
          <BackgroundImage
              Tag={`div`}
              className={'newsletter'}
              fluid={sfondo.childImageSharp.fluid}
            >
            <div className="newsletter__wrapper">
              <div className="newsletter__text">
                {formatMessage({ "id": "newsletter" })}
              </div>
              <div className="newsletter__title">
                <a rel="noopener noreferrer" target="_blank" className="newsletter__link" href="http://eepurl.com/dMDvAk">
                  {formatMessage({ "id": "iscriviti" })}
                </a>
              </div>
            </div>
          </BackgroundImage>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(Newsletter)